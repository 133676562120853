import { Tooltip, Typography } from 'cfa-react-components';
import styled from 'styled-components';
import {
  IconChevronLeft,
  IconLanguage,
  IconPrinter,
  IconShare2,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  setCategoryName,
  setDocumentName,
  setIsPrinting,
  setIsTranslating,
  setIsTridion,
} from 'store/document/slice';
import {
  tridionCategoryName,
  tridionDocumentName,
} from 'store/document/selectors';

const TridionDocumentSubheader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const categoryName = useSelector(tridionCategoryName);
  const documentName = useSelector(tridionDocumentName);
  const showTranslateIcon = localStorage.getItem('hasTranslatedValue');
  const [tridionCatName, setTridionCatName] = useState('');
  const [tridionDocName, setTridionDocName] = useState('');
  useEffect(() => {
    if (documentName) {
      localStorage.setItem('tridionDocumentName', documentName);
    }
  }, [documentName]);

  useEffect(() => {
    if (categoryName) {
      localStorage.setItem('tridionCategoryName', categoryName);
    }
  }, [categoryName]);
  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Generic.linkCopied')}
      </ToastMessageBlock>
    ));
  };

  const onBack = () => {
    history.goBack();
    dispatch(setCategoryName({ category: '' }));
    dispatch(setDocumentName({ name: '' }));
    dispatch(setIsTridion({ isTridion: false }));
    localStorage.setItem('tridionDocumentName', '');
    localStorage.setItem('tridionCategoryName', '');
    localStorage.setItem('isTridion', '');
    localStorage.setItem('hasTranslatedValue', '');
  };

  useEffect(() => {
    if (localStorage.getItem('tridionCategoryName')) {
      setTridionCatName(localStorage.getItem('tridionCategoryName'));
    }
  }, [setTridionCatName]);

  useEffect(() => {
    if (localStorage.getItem('tridionDocumentName')) {
      setTridionDocName(localStorage.getItem('tridionDocumentName'));
    }
  }, [setTridionDocName]);

  return (
    <SubheaderContainer>
      <BackContainer onClick={onBack}>
        <IconChevronLeft style={{ marginLeft: 16, marginRight: 8 }} />
        <ActionText>
          {tridionCatName ? tridionCatName : t('Generic.search')}
        </ActionText>
      </BackContainer>
      <DocumentName>{tridionDocName}</DocumentName>
      <ActionContainer>
        {showTranslateIcon ? (
          <TranslateIcon
            onClick={() => dispatch(setIsTranslating({ isTranslating: true }))}
          />
        ) : (
          <Tooltip
            content={t('Generic.noTranslationsAvailable')}
            placement="top"
            showOnElementEvents={['hover']}
          >
            <TranslateIcon $hideIcon={true} />
          </Tooltip>
        )}
        <IconPrinter
          onClick={() => dispatch(setIsPrinting({ isPrinting: true }))}
          style={{ marginRight: 12 }}
        />
        <IconShare2 onClick={onCopyLink} style={{ marginRight: 24 }} />
      </ActionContainer>
    </SubheaderContainer>
  );
};

const SubheaderContainer = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.elevation6};
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
`;

const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const DocumentName = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  font-weight: 700;
`;

const ActionText = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const ActionContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const TranslateIcon = styled(IconLanguage)`
  color: ${({ $hideIcon, theme }) => $hideIcon && theme.grayScale.gray3};
  cursor: ${({ $hideIcon }) => $hideIcon && 'default'};
  margin-right: 12px;
`;

export default TridionDocumentSubheader;
