import { createSelector } from 'reselect';

export const selectDocumentReducerState = state => state.document;

export const documentIsPrinting = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isPrinting,
);

export const documentIsTranslating = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isTranslating,
);

export const tridionCategoryName = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.category,
);

export const tridionDocumentName = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.name,
);

export const documentUrl = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.url,
);

export const isTridionDocument = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isTridion,
);
